import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];


//TODO: Normalize Data Maybe?
const Inventory = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.StarchInventory.Add:
            return UpdateArray(action.payload, state);
        case Constants.Actions.StarchInventory.Add_Rollback:
            const ivId = state.findIndex(iv => iv.id === action.meta.newInv.id);
            if (ivId !== -1) {
                return produce(state, pd => { pd.splice(ivId, 1) });
            } else {
                return state;
            }
        case Constants.Actions.StarchInventory.Add_Commit:
            return UpdateArray(action.payload?.data, state);
        case Constants.Actions.StarchInventory.ClearData:
            return initDataState;
        //case Constants.Actions.StarchInventory.Update:
        //    //Action will have the full plant data
        //    return state.map((p, ind) => {
        //        if (p.id === action.payload.id) {
        //            return produce(p, pd => { pd[action.payload.name] = action.payload.newValue });
        //        } else { return p; }
        //    });

        //case Constants.Actions.StarchInventory.Update_Commit:
        //    return UpdateArray(action.payload?.data, state);
        //case Constants.Actions.StarchInventory.Update_Rollback:
        //    //Action will have the full plant data
        //    return state.map((p, ind) => {
        //        if (p.id === action.meta.updatePayload.id) {
        //            return produce(p, pd => { pd[action.meta.updatePayload.name] = action.meta.updatePayload.oldValue });
        //        } else { return p; }
        //    });
        case Constants.Actions.StarchInventory.GetByParentID:
            return UpdateArrayRange(action.data, state);
        case Constants.Actions.StarchInventory.GetByVisitID:
            return UpdateArray(action.data, state);
        case Constants.Actions.StarchInventory.Delete:
            return UpdateArray(produce(action.payload, d => {
                d.deleted = true;
            }), state);
        case Constants.Actions.StarchInventory.Delete_Commit:
            return state;
        case Constants.Actions.StarchInventory.Delete_Rollback:
            return UpdateArray(action.meta.delInv, state);
        default:
            return state;
    }
}
export default Inventory;