import Constants from '../../constants';
import React, { useEffect, useState } from 'react';
import { GetAttachmentSel } from '../../selectors/AttachmentSelectors';
import { PopulateFormulaStepsStrings, SelFormulaStepsByFormulaID } from '../../selectors/FormulaSelectors';
//MUI
const constants = Constants;

const convertUnits = (formula, isMetric) => {
    // Convert units (e.g., weight, volume, cost) based on whether isMetric is true or false
    return {
        ...formula,
        batchWeight: isMetric ? (formula.batchWeight * 0.453592).toFixed(2) : formula.batchWeight, // Convert weight (lbs to kg)
        batchVolume: isMetric ? (formula.batchVolume * 3.78541).toFixed(2) : formula.batchVolume, // Convert volume (gal to L)
        total_Two: isMetric ? ((formula.total_Two * 0.453592) / 3.78541).toFixed(2) : formula.total_Two, // Convert total_Two
        costDry: isMetric ? (formula.costDry * 2.20462).toFixed(2) : formula.costDry, // Convert costDry (kg to lbs)
        costGal: isMetric ? (formula.costGal * 0.264172).toFixed(2) : formula.costGal, // Convert costGal (L to gal)
    };
};

const convertUnit = (value, unit, isMetric) => {
    if (isMetric) {
        switch (unit) {
            case 0: // lbs → kg
                return (value * 0.453592).toFixed(2);
            case 5: // gal → L
                return (value * 3.78541).toFixed(2);
            case 1: // oz → kg
                return (value * 0.0283495).toFixed(2);
            case 3: // °F → °C
                return ((value - 32) * 5/9).toFixed(2);
            case 4: // °C → °C (No conversion needed)
                return value.toFixed(2);
            default:
                return value; 
        }
    } else { // Imperial
        switch (unit) {
            case 0: // kg → lbs
                return value;
            case 5: // L → gal
                return value;
            case 1: // kg → oz
                return value;
            case 3: // °C → °F
                return value;
            case 4: // °C → °C (No conversion)
                return value;
            default:
                return value;
        }
    }
};

const convertSteps = (steps, isMetric) => {
    return steps.map(step => {
        const convertedValue = convertUnit(step.value, step.unit, isMetric); // Apply unit conversion to each step's value
        return {
            ...step,
            value: convertedValue,
        };
    });
};

export const getFormulaValue = (DetailsObj, plant, state, isMetric) => {
    const steps = PopulateFormulaStepsStrings(SelFormulaStepsByFormulaID(state, DetailsObj.id), isMetric);
    let temp = GetAttachmentSel(state, DetailsObj.signatureImageID);
    const signature = temp ? temp : null;

    const convertedFormula = convertUnits(DetailsObj, isMetric); // Apply unit conversion to formula object
    const convertedSteps = convertSteps(steps, isMetric); // Apply unit conversion to formula steps

    const dJSON = {
        Formula: { ...DetailsObj, ...convertedFormula }, // Combine original and converted formula data
        FormulaSteps: convertedSteps, // Converted formula steps
        Signature: signature,
    };

    return dJSON;
};




// import Constants from '../../constants';
// import React, { useEffect, useState } from 'react';
// import { GetAttachmentSel } from '../../selectors/AttachmentSelectors';
// import { PopulateFormulaStepsStrings, SelFormulaStepsByFormulaID } from '../../selectors/FormulaSelectors';
// //MUI
// const constants=Constants;
// export const getFormulaValue = (DetailsObj, plant, state) =>  {
//     const steps = PopulateFormulaStepsStrings(SelFormulaStepsByFormulaID(state,DetailsObj.id));
//     let temp = GetAttachmentSel(state,DetailsObj.signatureImageID)
//     const signature = temp ?  temp : null ;
    
//     const dJSON ={Formula:DetailsObj, FormulaSteps:steps, Signature:signature };

//     return dJSON

// }