import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useThermalImages,useThermalMachines,useVesselsByMachineID } from '../../data/ThermalImagesData';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DataForm, DataImageAttachment, DataTextBox } from '../FormManager';
import { UpdateAction } from '../../actions/DataActions';
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';
const OtherGuid = '11111111-1111-1111-1111-111111111111';
const ThermalImagesList =({thermal})=>{
    const images=useThermalImages(thermal?.id);
    const machines=useThermalMachines(thermal?.lineID);
    return images.map(i=><ThermalImageItem thermalImage={i} machines={machines} visitID={thermal?.visitID} />);
}
const ThermalImageItem=({thermalImage,machines,visitID})=>{
    const dispatch=useDispatch();
    //Event Funcs
    const machineChange=(newMachineID)=>{
        if(newMachineID==Constants.EmptyGuid)
            return;
        ClearVesselData();
        dispatch(UpdateAction(Constants.Actions.ThermalImages, 'machineID', thermalImage, newMachineID));
        //Update MachineName
        if(newMachineID===OtherGuid){
            dispatch(UpdateAction(Constants.Actions.ThermalImages, 'machineName', thermalImage, 'Other'));
        } else {
            const machine = machines.find(m => m.id === newMachineID);
            if (machine.type === 5) {
                dispatch(UpdateAction(Constants.Actions.ThermalImages, 'vesselName', thermalImage, ''));
            }
            dispatch(UpdateAction(Constants.Actions.ThermalImages, 'machineName', thermalImage, machine.name));
        }
    };
    const ClearVesselData=()=>{
        dispatch(UpdateAction(Constants.Actions.ThermalImages, 'machineVesselID', thermalImage, Constants.EmptyGuid));
        dispatch(UpdateAction(Constants.Actions.ThermalImages, 'vesselName', thermalImage, ''));
    }
    const vesselChange=(newVessel)=>{
        dispatch(UpdateAction(Constants.Actions.ThermalImages, 'machineVesselID', thermalImage, newVessel.id));
        dispatch(UpdateAction(Constants.Actions.ThermalImages, 'vesselName', thermalImage, newVessel.name));
    }
    //JSX
    return <div key={"img_"+thermalImage.id}>
    <DataForm  actionType={Constants.Actions.ThermalImages} data={thermalImage} >
        <Grid container spacing={3} >
            <Grid item xs={6}><DataImageAttachment name={'leftImageID'}/></Grid>
            <Grid item xs={6}><DataImageAttachment name={'rightImageID'}/></Grid>
            <Grid item xs={2}>
                <MachineSelect machines={machines} onMachineChange={machineChange} thermalImage={thermalImage} />
            </Grid>
            <Grid item xs={4}>
                <VesselView thermalImage={thermalImage} machines={machines} onVesselChange={vesselChange} />
            </Grid>
            <Grid item xs={6}><DataTextBox name="boardCombo" label="Board Combinations" fullWidth /></Grid>
            <Grid item xs={9}><DataTextBox name="comments" label="Comment" fullWidth /></Grid>
            <Grid item xs={3}>         
                <KeyItemCheckBox entity={thermalImage} visitID={visitID}
                    setReference={(entity) => entity.machineName+' '+entity.vesselName } field=""
                    entityType="Thermal Image" edit={true} tabIndex="-1"
                /> 
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item xs={12}>
                
            </Grid>
        </Grid>
    </DataForm>
    </div>; 
}
export default ThermalImagesList;
export const MachineSelect = ({machines,onMachineChange,thermalImage}) => {
    //props machines, type, onMachineChange(glMachine,type,newMachineID,oldMachineID), glMachine
    const machineSelID = 'ms_MachineSel_' + thermalImage.id;
    const machineID = thermalImage.machineID

    const onChange = e => {
        if (e.target.value === machineID)
            return;
        onMachineChange( e.target.value);
    };

    const options = [
        <option key={Constants.EmptyGuid} value={Constants.EmptyGuid}>Select</option>,
        ...machines.map(m => <option key={m.id} value={m.id}>{m.name}</option>),
        <option key={OtherGuid} value={OtherGuid}>Other</option>,
    ];
    
    return <FormControl variant="outlined" size="small">
        <InputLabel htmlFor={machineSelID} shrink={true}>Machine</InputLabel>
        <Select
            native
            name={'Machine'}
            value={machineID}
            onChange={onChange}
            id={machineSelID}
            label="Machine"
        >
            {options}
        </Select>
    </FormControl>;
};
export const VesselView = ({ thermalImage, machines, onVesselChange }) => {
    const [vesselName, setVesselName] = useState(thermalImage.vesselName || ''); 
    const machine = machines.find(m => m.id === thermalImage.machineID);

    const [debounceTimer, setDebounceTimer] = useState(null);

    useEffect(() => {
        setVesselName(thermalImage.vesselName || ''); 
    }, [thermalImage.vesselName]);

    const handleVesselNameChange = (e) => {
        const value = e.target.value;
        setVesselName(value);

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const timer = setTimeout(() => {
            onVesselChange({ id: thermalImage.machineVesselID, name: value });
        }, 1200);

        setDebounceTimer(timer); 
    };
    if (!thermalImage.machineID || thermalImage.machineID === Constants.EmptyGuid || thermalImage.machineID === OtherGuid) {
        return null;
    }
    if (machine.type === 5) {
        return (
            <TextField
                label=""
                value={vesselName}  
                onChange={handleVesselNameChange} 
                size="small"
            />
        );
    }
    return null;
};
export const VesselSelect = ({machine,onVesselChange,thermalImage})=>{
    const vessels=useVesselsByMachineID(machine.id);
    const vesselSelID = 'ms_VesselSel_' + thermalImage.id;
    const vesselID = thermalImage.machineVesselID;

    const onChange = e => {
        if (e.target.value === vesselID)
            return;
        const vessel= vessels.find(v=>v.id==e.target.value);
        onVesselChange(vessel);
    };

    const options = [
        <option key={Constants.EmptyGuid} value={Constants.EmptyGuid}>Select</option>,
        ...vessels.map(m => <option key={m.id} value={m.id}>{m.name}</option>) 
    ];
    
    return <FormControl variant="outlined" size="small">
        <InputLabel htmlFor={vesselSelID} shrink={true}>Vessel</InputLabel>
        <Select
            native
            name={'Vessel'}
            value={vesselID}
            onChange={onChange}
            id={vesselSelID}
            label="Vessel"
        >
            {options}
        </Select>
    </FormControl>;
}