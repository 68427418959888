//Modules
import React, { useState, useEffect } from 'react';
import Constants from '../../constants';
import produce from "immer";
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux'
//MUI
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useWindowSize from '../MetaData/useWindowSize';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Grid';
//Compontents
import { FormulaSummary } from './FormulaSummary';
import AddCustomToolbar from "../AddCustomToolbar";
import CustomToolbar, { DeleteButton, UpButton, DownButton, EditButton } from '../Common/CustomToolbar';
import { StepAddDialog } from './StepAddDialog';
import { StepSelectFormik, StepTypeView } from './StepTypeViews';
import { UnitSelectFormik, UnitView } from './UnitViews';
import { GetKeyItemByEntityID, DeleteKeyItem, UpdateKeyItem, AddKeyItem } from '../../actions/KeyItemActions';
import KeyItemGridButton from '../KeyItems/KeyItemGridButton';
//Actions
import { FormulaCalcSummary } from '../../actions/FormulaActions';
import { AddFormulaStep, ChangeFormulaStepNumber, DeleteFormulaStep, UpdateFormulaStep, ReOrderFormulaSteps } from '../../actions/FormulaStepActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
//Selectors 
import { SelFormulaByID,SelFormulaStepsByFormulaID,FormulaStepsSel } from '../../selectors/FormulaSelectors';
const FormulaEdit = props => {
    const { measurementSystem } = props;
    const [modalOpen, setModal] = useState(false);
    const dispatch = useDispatch();
    const size = useWindowSize();
    const steps = useSelector(s=>FormulaStepsSel(s));
    const formula = useSelector(s=>SelFormulaByID(s,props.formulaID));
    const fSteps =useSelector(s=>SelFormulaStepsByFormulaID(s,props.formulaID))
    const [selectedRows, setSelectedRows] = useState([]);
    const [addStep, setAddStep] = useState(false);
    const [keyItemchecked, setkeyItemchecked] = useState(false);
    const isMetric = measurementSystem === 'metric'; 

    // const convertCost = (cost, weightInLbs, isMetric) => {
    //     if (isMetric) {
    //         const weightInKg = weightInLbs * 0.453592;
    //         const totalCostInPounds = weightInLbs * cost;
    //         const costPerKg = totalCostInPounds / weightInKg;
    //         if (costPerKg === 0) {
    //             return 0;  
    //         }
    //         return costPerKg.toFixed(4);  
    //     } else {
    //         return cost; 
    //     }
    // };

    const convertCost = (cost, weightInLbs, unit, isMetric) => {
        let convertedCost = cost; 
        
        if (isMetric) {
            switch(unit) {
                case 0: // lbs → kg
                    const weightInKg = weightInLbs * 0.453592; 
                    convertedCost = (cost * weightInLbs) / weightInKg;
                    break;
                case 5: // gal → L
                    convertedCost = (cost * weightInLbs) / (weightInLbs * 3.78541); 
                    break;
                case 1: // oz → kg
                    const weightInKgFromOz = weightInLbs * 0.0283495; 
                    convertedCost = (cost * weightInLbs) / weightInKgFromOz;
                    break;
                case 3: // °F → °C
                    convertedCost = cost / 1.8;
                    break;
                case 4: // °C → °C (No conversion needed)
                    break;
                default:
                    break;
            }
        } else { // For Imperial system (lbs, gal, oz, etc.)
            switch(unit) {
                case 0: // kg → lbs
                    return (cost);
                case 5: // L → gal
                    return (cost);
                case 1: // kg → oz
                    return (cost);
                case 3: // °C → °F
                    return (cost);
                case 4: // °C → °C (No conversion needed)
                    return (cost);
                default:
                    return (cost);
            }
        }
        if (convertedCost === 0) {
            return '0'; // Or you can return '0.0' or '0.0000' as you prefer
        }
        if (cost === 0) {
            return '0'; // Or you can return '0.0' or '0.0000' as you prefer
        }
        return convertedCost.toFixed(4); 
    };
    


        const convertUnit = (value, unit, isMetric) => {
            if (isMetric) {
                switch (unit) {
                    case 0:
                        return (value * 0.453592).toFixed(2);
                    case 5:
                        return (value * 3.78541).toFixed(2);
                    case 1:
                        return (value * 0.0283495).toFixed(2);
                    case 3:
                        return ((value - 32) * 5/9).toFixed(2);
                    case 4:
                        return value.toFixed(2);
                    default:
                        return value; 
                }
            } else {
                switch (unit) {
                    case 0:
                        return (value);
                    case 5:
                        return (value);
                    case 1: 
                        return (value);
                    case 3:
                        return (value);
                    case 4:
                        return (value);
                    default:
                        return value;
                }
            }
        };
    useEffect(() => {
        if (props.formulaID) {
            //Trigger ReCalculate when loading a new formula. 
            dispatch(ReOrderFormulaSteps(props.formulaID));
            dispatch(FormulaCalcSummary(props.formulaID));
        }
    }, [props.formulaID])
    //Added to Handle Delete
    if (!formula)
        return null;
    const initStep = {
        stepNumber: 0, stepType: 999, stepDescription: '', lbsPerGallon: 0, dryPercentage: 0
        , product: '', value: 0, unit: 0, mixTime: 0, cost: 0,other:''
    };
    let compactGrid = false;
    if (size.width < 960) { compactGrid = true; }
    const columns = [
        { name: "stepNumber", label: "#" },
        {
            name: "stepType", label: "Ingredient", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <StepTypeView value={value} />;
                }
            }
        }
    ];
    if (compactGrid) {
        columns.push({
            name: "value", label: "Amount", options: {
                customBodyRender: (value, tableMeta) => {
                    const unit = fSteps[tableMeta.rowIndex].unit;
                    const convertedValue = convertUnit(value, unit, isMetric);
                    return `${convertedValue}`;
                }
            }
        });
        columns.push({
            name: "unit",
            label: "Unit",
            options: {
                customBodyRender: (value) => {
                    if (isMetric) {
                        switch (value) {
                            case 0: return 'kg';
                            case 5: return 'L';
                            case 1: return 'kg'; 
                            case 3: return '°C';
                            case 4: return '°C';
                            default: return 'N/A'; 
                        }
                    }
                    switch (value) {
                        case 0: return 'lbs';
                        case 5: return 'gal';
                        case 1: return 'oz';
                        case 3: return '°F';
                        case 4: return '°C';
                        default: return 'N/A';
                    }
                }
            }
        });
    }
    else {   
        columns.push({ name: "product", label: "Type" });
        columns.push({
            name: "value", label: "Amount", options: {
                customBodyRender: (value, tableMeta) => {
                    const unit = fSteps[tableMeta.rowIndex].unit;
                    const convertedValue = convertUnit(value, unit, isMetric);
                    return `${convertedValue}`;
                }
            }
        });
        columns.push({
            name: "unit",
            label: "Unit",
            options: {
                customBodyRender: (value) => {
                    if (isMetric) {
                        switch (value) {
                            case 0: return 'kg';
                            case 5: return 'L';
                            case 1: return 'kg'; 
                            case 3: return '°C';
                            case 4: return '°C';
                            default: return 'N/A'; 
                        }
                    }
                    switch (value) {
                        case 0: return 'lbs';
                        case 5: return 'gal';
                        case 1: return 'oz';
                        case 3: return '°F';
                        case 4: return '°C';
                        default: return 'N/A';
                    }
                }
            }
        });
        columns.push({ name: "mixTime", label: "Mix" });
        // columns.push({
        //     name: "cost", label: "Cost", options: {
        //         customBodyRender: (value, tableMeta) => {
        //             const unit = fSteps[tableMeta.rowIndex].unit; // Assuming unit info per row
        //             const weightInLbs = fSteps[tableMeta.rowIndex].value; // Assuming 'value' is weight in lbs
        //             const convertedCost = convertCost(value, weightInLbs, isMetric); // Convert cost based on weight and measurement system
        //             return `${convertedCost}`;
        //         }
        //     }
        // });
        columns.push({
            name: "cost", label: "Cost", options: {
                customBodyRender: (value, tableMeta) => {
                    const unit = fSteps[tableMeta.rowIndex].unit;  // Get the unit for the current row
                    const weightInLbs = fSteps[tableMeta.rowIndex].value;  // Assuming 'value' is the weight in lbs
                    const convertedCost = convertCost(value, weightInLbs, unit, isMetric);  // Convert the cost
                    return `${convertedCost}`;  // Return the converted cost as a string
                }
            }
        });
        

        columns.push({ name: "other", label: "Other" });
        columns.push({ name: "dryPercentage", label: "DryPercentage", options: { display: false } });
        columns.push({ name: "lbsPerGallon", label: "LbsPerGallon", options: { display: false } });
    }
    const ClickAdd = () => {
        setAddStep(true);
        setModal(true);
    };
    const ClickDelete = () => {
        dispatch(ConfirmAction("Do you want to delete this Step?", (dispatch, getState) => {
            dispatch(DeleteFormulaStep(fSteps[selectedRows[0]]));
            dispatch(FormulaCalcSummary(props.formulaID));
            setSelectedRows([]);
        }
        , null));
        
    };
    const ClickEdit = () => {
        setAddStep(false);
        setModal(true);
    };
    const ChangeOrder = (isUp) => {
        dispatch(ChangeFormulaStepNumber(fSteps[selectedRows[0]], !isUp));
        let newRow = selectedRows[0];
        if (!isUp) {
            newRow += 1;
        } else {
            newRow -= 1;
        }
        if (newRow >= 0 && newRow < fSteps.length+1) {
            setSelectedRows([newRow]);
        }
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const handleStepAdd = (formVal) => {
        setModal(false);
        if (addStep) {
            formVal.formulaID = props.formulaID
            formVal.stepNumber = fSteps.length + 1;
            dispatch(AddFormulaStep(formVal));
            dispatch(FormulaCalcSummary(props.formulaID));
        } else {
            dispatch(UpdateFormulaStep(formVal, fSteps[selectedRows[0]]));
            dispatch(FormulaCalcSummary(props.formulaID));
            if (keyItemchecked) {
                addNewKeyItem(formVal)
            }
        }
    };
    const StepToLabel = (v) => {
        let lbl = v;
        if (v === "Caustic_Percentage") { lbl = "Caustic"; }
        if (v === "Caustic_Dry") { lbl = "Caustic Dry"; }
        if (v === "Borax_Liquid") { lbl = "Borax Liquid"; }
        if (v === "Starch_Specialty") { lbl = "Specialty Starch"; }
        if (v === "Liquid_Specialty") { lbl = "Specialty Liquid"; }
        if (v === "Liquid_Add") { lbl = "Liquid Additive"; }
        return lbl;
    };
    const addNewKeyItem = (data) => {
        const fs = fSteps[selectedRows[0]];
        let ingridiantText = StepToLabel(Object.keys(Constants.Enums.StepType).find(key => Constants.Enums.StepType[key] == fs.stepType))
        const newKeyItem = {
            id : uuidv4(),
            visitID: props.visitID,
            entityID: fs.id,
            url: 'VisitOverview/'+props.visitID,
            entityType: 'FormulaStep',
            type: 'Operational',
            reference: formula.name + ' - Step:' + fs.stepNumber + ' ' + ingridiantText,
            entityData: JSON.stringify(fs),
            notes: data.comment,
            isPriority: '-'
        };
        dispatch(AddKeyItem(newKeyItem));
    };
    const handleKeyItem = (value) => {
            setkeyItemchecked(value)
    };
    let keyButton = null;
    if (props.visitID) {
        const fs = fSteps[selectedRows[0]];
        if (fs) {
            keyButton = <KeyItemGridButton entityType={'FormulaStep'}
                entity={fs} reference={formula.name + ' - Step:' + fs.stepNumber}
                visitID={props.visitID} url={'VisitOverview/'+props.visitID} />;
        }
    }
    const SelectToolBar = <CustomToolbar>
        <UpButton onClick={() => ChangeOrder(true)} />
        <DownButton onClick={() => ChangeOrder(false)} />
        <EditButton onClick={ClickEdit} />
        { keyButton }
        <DeleteButton onClick={ClickDelete} />
    </CustomToolbar>;
    const options = {
        filterType: 'checkbox',
        selectableRows: 'single',
        selectableRowsOnClick: true,
        responsive: 'standard',
        print: false, pagination: false, download: true, searchOpen: false, sort: false,
        sortFilterList: false, viewColumns: true, filter: false, search: false,
        fixedHeader: true,
        tableBodyHeight: '700px',
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => SelectToolBar,
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows,
        textLabels: {
            selectedRows: { text: '' }
        },
        setRowProps:()=>({
            onDoubleClick: (row) => {
                if (selectedRows.length == 0) {
                    const targetID = row.currentTarget.id;
                    const dataRow = parseInt(targetID.substring(targetID.lastIndexOf('-') + 1));
                    if (!isNaN(dataRow)) {
                        setSelectedRows([dataRow]);
                        ClickEdit();
                    }
                } else {
                    ClickEdit();
                }      
                //;
            }
        })
    };
  
    const getInitValues = () => {
        if (modalOpen) {
            if (addStep) {
                return initStep;
            } else {
                return produce(fSteps[selectedRows[0]], d => { d.comment=''});
            }
        } else
            return null;
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={9} >
                <Card>
                    <CardContent>
                        <MUIDataTable
                            title={"Steps"}
                            data={fSteps}
                            columns={columns}
                            options={options}
                            className="largePrint"
                        />
                        <StepAddDialog modalOpen={modalOpen} closeModal={() => setModal(false)}
                            onSumbit={handleStepAdd} steps={fSteps} initialValues={getInitValues()}
                            allSteps={steps} addStep={addStep} handleKeyItem={handleKeyItem}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormulaSummary formulaID={props.formulaID}  />
            </Grid>
        </Grid>
    );
};
export default FormulaEdit;