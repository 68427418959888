import { usePlantData, useReportName, useReportUser } from '../../misc/UseFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { SelFormulaStepsByFormulaID,PopulateFormulaStepsStrings } from '../../selectors/FormulaSelectors';
import React, { useEffect, useContext, useState } from 'react';
import { GetAttachmentSel } from '../../selectors/AttachmentSelectors';
import ReportViewer from '../Report/ReportViewer';
import { GetAttachment } from '../../actions/AttachmentActions';
const FormulaPrintView = props => {
    const { formula, isMetric, onClose } = props;
    const printData = useFormulaPrintData(formula, isMetric);
    if (printData.Loaded) {
        return <ReportViewer type="Formula" name="Formula" printData={printData} onClose={onClose} />;
    }
    return <div>Loading Data</div>;
};
export default FormulaPrintView;

export const useFormulaPrintData = (formula, isMetric) => {
    const [loaded, setLoaded] = useState(false);
    const UserData = useReportUser();
    const dispatch = useDispatch();
    // Conversion logic for units and costs
    const convertUnits = (formula, isMetric) => {

        // Convert all units (e.g., weight, volume) and costs based on isMetric
        return {
            ...formula,
            batchWeight: isMetric ? (formula.batchWeight * 0.453592).toFixed(2) : formula.batchWeight, // Convert weight
            batchVolume: isMetric ? (formula.batchVolume * 3.78541).toFixed(2) : formula.batchVolume, // Convert volume
            total_Two: isMetric ? ((formula.total_Two * 0.453592) / 3.78541).toFixed(2) : formula.total_Two, // Convert total_Two
            costDry: isMetric ? (formula.costDry * 2.20462).toFixed(2) : formula.costDry, // Convert costDry
            costGal: isMetric ? (formula.costGal * 0.264172).toFixed(2) : formula.costGal, // Convert costGal
        };
    };

    const convertCost = (cost, weightInLbs, unit, isMetric) => {
        let convertedCost = cost; 
        
        if (isMetric) {
            switch(unit) {
                case 0: // lbs → kg
                    const weightInKg = weightInLbs * 0.453592; 
                    convertedCost = (cost * weightInLbs) / weightInKg;
                    break;
                case 5: // gal → L
                    convertedCost = (cost * weightInLbs) / (weightInLbs * 3.78541); 
                    break;
                case 1: // oz → kg
                    const weightInKgFromOz = weightInLbs * 0.0283495; 
                    convertedCost = (cost * weightInLbs) / weightInKgFromOz;
                    break;
                case 3: // °F → °C
                    convertedCost = cost / 1.8;
                    break;
                case 4: // °C → °C (No conversion needed)
                    break;
                default:
                    break;
            }
        } else { // For Imperial system (lbs, gal, oz, etc.)
            switch(unit) {
                case 0: // kg → lbs
                    return (cost);
                case 5: // L → gal
                    return (cost);
                case 1: // kg → oz
                    return (cost);
                case 3: // °C → °F
                    return (cost);
                case 4: // °C → °C (No conversion needed)
                    return (cost);
                default:
                    return (cost);
            }
        }
        if (convertedCost === 0) {
            return '0'; // Or you can return '0.0' or '0.0000' as you prefer
        }
        if (cost === 0) {
            return '0'; // Or you can return '0.0' or '0.0000' as you prefer
        }
        return convertedCost.toFixed(4); 
    };
    


        const convertUnit = (value, unit, isMetric) => {
            if (isMetric) {
                switch (unit) {
                    case 0:
                        return (value * 0.453592).toFixed(2);
                    case 5:
                        return (value * 3.78541).toFixed(2);
                    case 1:
                        return (value * 0.0283495).toFixed(2);
                    case 3:
                        return ((value - 32) * 5/9).toFixed(2);
                    case 4:
                        return value.toFixed(2);
                    default:
                        return value; 
                }
            } else {
                switch (unit) {
                    case 0:
                        return (value);
                    case 5:
                        return (value);
                    case 1: 
                        return (value);
                    case 3:
                        return (value);
                    case 4:
                        return (value);
                    default:
                        return value;
                }
            }
        };

    const convertSteps = (steps, isMetric) => {
        return steps.map(step => {
            const convertedCost = convertCost(step.cost, step.value, step.unit, isMetric);       
            const convertedValue = convertUnit(step.value, step.unit, isMetric);
            return {
                ...step,
                cost: convertedCost,
                value: convertedValue,
            };
        });
    };

    useEffect(() => {
        if (formula) {
            dispatch(GetAttachment(formula.signatureImageID));
            setTimeout(() => setLoaded(true), 100);
        }
    }, [formula]);

    const plant = usePlantData(formula?.plantID);
    const steps = PopulateFormulaStepsStrings(useSelector(s => SelFormulaStepsByFormulaID(s, formula?.id)), isMetric);
    const signature = useSelector(s => GetAttachmentSel(s, formula?.signatureImageID));

    const reportName = useReportName(formula.name, plant?.name);

    const retVal = { Loaded: false, Data: {}, ReportName: reportName, EmailSubject: 'Formula ' + formula?.name };

    if (loaded) {
        const convertedFormula = convertUnits(formula, isMetric); // Apply conversion
        const convertedSteps = convertSteps(steps, isMetric); // Apply conversion to FormulaSteps

        const dJSON = {
            Formula: { ...formula, ...convertedFormula }, // Combine original and converted data
            FormulaSteps: convertedSteps, // Converted FormulaSteps
            User: UserData,
            Plant: plant,
            Signature: signature,
        };

        const Data = { FormulaData: JSON.stringify(dJSON) };

        retVal.Loaded = true;
        retVal.Data = Data;
        console.log("**************************---------------", dJSON); // For debugging
    }

    return retVal;
};



