import Constants from '../constants';
import { createSelector } from 'reselect';
import { StepToLabel } from '../components/Formula/StepTypeViews';
export const FormulaStepsSel = state=>state.FormulaSteps.filter(fs=>!fs.deleted);
export const SelFormulaStepsByFormulaID = createSelector(FormulaStepsSel,(_,formulaID)=>formulaID,
    (steps,formulaID)=>{
        return steps
        .filter(s => s.formulaID === formulaID)
        .sort((a, b) => a.stepNumber - b.stepNumber);
    }
);

const convertUnitTextToMetric = (unitText) => {
    switch (unitText) {
        case 'lbs':
            return 'kg'; // Convert lbs to kg
        case 'gal':
            return 'ltr'; // Convert gal to ltr
        case 'F':
            return 'C';   // Convert Fahrenheit to Celsius
        case 'oz':
            return 'kg';  // Convert oz to kg
        default:
            return unitText; // Return the unit as is for unsupported units
    }
};

export const PopulateFormulaStepsStrings = (stepsArray, isMetric) => {
    return stepsArray.map(s => {
        const stepTypeText = Object.keys(Constants.Enums.StepType).find(key => Constants.Enums.StepType[key] == s.stepType);
        const unitText = Object.keys(Constants.Enums.FormulaUnit).find(key => Constants.Enums.FormulaUnit[key] == s.unit);
        
        // Convert the unitText to metric only if isMetric is true
        const convertedUnitText = isMetric ? convertUnitTextToMetric(unitText) : unitText;

        return {
            ...s,
            stepTypeText: StepToLabel(stepTypeText),
            unitText: convertedUnitText // Set the converted unitText
        };
    });
};
export const SelFormulas = state=>state.Formulas.filter(f=>!f.deleted);
export const SelFormulaByID =createSelector(SelFormulas,(_,fID)=>fID,
    (formulas,fID)=>{
        return formulas.find(f=>f.id===fID);
    }
);
export const SelActivePlantFormulas=createSelector(SelFormulas,(_,PlantID)=>PlantID,
    (formulas,PlantID)=>{
        return formulas.filter(f=>f.plantID===PlantID && f.active)
    }
);