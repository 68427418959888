import { createSelector } from 'reselect';
export const SelVisits = state =>state.Visits;
export const SelVisitByID = createSelector(SelVisits,(state,visitID)=>visitID,
    (visits,visitID)=>visits.find((v) => v.id == visitID))
export const SelInventory = state => {
    const flattenedInventories = state.Inventory.flat();
    return flattenedInventories.filter(i => !i.deleted);
};
export const SelInventoryByVisitID = createSelector(
    SelInventory,
    (_, visitID) => visitID,
    (inventories, visitID) => {

        const visitIDStr = String(visitID).trim().toLowerCase();

        const flattenedInventories = inventories.flat();

        const filteredInventories = flattenedInventories.filter(i => {
            if (i && i.visitID) {
                const inventoryVisitID = String(i.visitID).trim().toLowerCase();
                return inventoryVisitID === visitIDStr;
            }
            return false;
        });

        return filteredInventories;
    }
);



export const GetMeasurements = state=>state.StarchMeasurement.filter(i=>!i.deleted);;
export const SelMeasuresByVisitID = createSelector(GetMeasurements,(_,visitID)=>visitID,(measurements,visitID)=>{
    return measurements.filter(m=>m.visitID===visitID);
});
export const FormatLocation = (location)=>{
    switch(location){
        case 0:
            return "Mixer";
        case 1:
            return "Storage";
        case 2:
            return "Doser - SF #1";
        case 3: 
            return "Doser - SF #2";
        case 4:
            return "Doser - DB"
        default:
            return "Other";
    }
};
export const SKItemsSel=state=>state.StarchKitchenItems.filter(i=>!i.deleted);;
export const SelSKItemsByVisitID = createSelector(SKItemsSel,(_,visitID)=>visitID,
    (Items,visitID)=>{
        return Items.filter(ski=>ski.visitID===visitID).sort((a, b) => a.order - b.order);;
    }
);