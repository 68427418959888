import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
//Components
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
//Actions
import { GetInventoryByVisitID, AddInventory, DeleteInventory } from '../../actions/InventoryActions';
//Selectors
import {SelInventoryByVisitID } from '../../selectors/VisitSelectors';
const InventoryGrid = props => {
    const dispatch = useDispatch();
    const inventories = useSelector(s => SelInventoryByVisitID(s,props.visitID));
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalOpen, setModal] = useState(false);
    useEffect(() => {
        dispatch(GetInventoryByVisitID(props.visitID));
    
        // Log inventories state after dispatching
    }, [props.visitID]); // Dependency on visitID
   
    const columns = [{ name: "product", label: "Product" },
        { name: "quantity", label: "Quantity" }];
   
    const ClickDelete = () => {
      if (selectedRows.length > 0) {
        const selectedInventory = inventories[selectedRows[0]];
        // Pass the inventory object to the delete action
        dispatch(DeleteInventory(selectedInventory));
        setSelectedRows([]); // Clear selected rows after deletion
      }
    };
    const ClickAdd = (e) => {
        setModal(true);
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return (
                <AddCustomToolbar onClick={ClickAdd} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}  clickDelete={ClickDelete} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows
    };
    const handleSubmit = (e) => {
        console.log("Handle Submit", e);
        setModal(false);
        dispatch(AddInventory(e));
    }
    return <>
            <MUIDataTable
            title={"Inventory"}
            data={inventories}
            columns={columns}
            options={options}
        />
        {/* <MUIDataTable
            title="Inventory"
            data={inventories.map((inventory) => ({
                product: inventory.product,  // Access the individual field
                quantity: inventory.quantity,  // Access the individual field
            }))}
            columns={columns}
            options={options}
        /> */}
        <InventoryFormDialog modalOpen={modalOpen} closeModal={() => setModal(false)}
            onSubmit={handleSubmit} initialValues={{ product: '', quantity: 0, visitID:props.visitID}} />
    </>;
};
export const InventoryFormDialog = props => {
    const validate = (values)=>{
        const errors={};
        if(!values.product)
            errors.product="Must have value";
        if(!values.quantity || isNaN(values.quantity))
            errors.quantity="Not a number";
        return errors;
    }
    return (
        <Dialog open={props.modalOpen} onClose={props.closeModal} >
            <Formik initialValues={props.initialValues} onSubmit={props.onSubmit} validate={validate}>
                <Form>
                    <DialogTitle >Add Inventory</DialogTitle >
                    <DialogContent>
                        <div>
                            <Field
                                component={TextField}
                                name="product"
                                type="text"
                                label="Product"
                            />
                        </div>
                        <div>
                            <Field
                                component={TextField}
                                name="quantity"
                                type="number"
                                label="Quantity"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Add</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog >
    );
};
export default InventoryGrid;