import Constants from '../../constants';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
const useStyles = makeStyles((theme) => ({
    tirTable: {
        border: "1px solid black",
        marginLeft:"40px",
        "& td": { border: "1px solid black" }
    }
}));
const GlueRoll_TIRView = props => {
    const classes = useStyles();
    return <DataFormContext.Consumer>
        {({ actionType, data }) => (
            <table className={classes.tirTable}>
                <thead>
                    <tr>
                        <td colspan={4} align="center">
                           TIR
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>ADH. ROLL TIR</td>
                        <td>METER ROLL TIR</td>
                        <td>TOTAL VARIATION</td>
                    </tr>
                    <tr>
                        <td>OPERATOR</td>
                        <td>{data.glueRollOperator}</td>
                        <td>{data.meterRollOperator}</td>
                        <td>{data.glueRollOperator + data.meterRollOperator}</td>
                    </tr>
                    <tr>
                        <td>CENTER</td>
                        <td>{data.glueRollCenter}</td>
                        <td>{data.meterRollCenter}</td>
                        <td>{data.glueRollCenter + data.meterRollCenter}</td>
                    </tr>
                    <tr>
                        <td>Drive</td>
                        <td>{data.glueRollDrive}</td>
                        <td>{data.meterRollDrive}</td>
                        <td>{data.glueRollDrive + data.meterRollDrive}</td>
                    </tr>
                </tbody>
            </table>)}
    </DataFormContext.Consumer>;;
}
export default GlueRoll_TIRView;