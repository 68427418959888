import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams,useNavigate,useLocation  } from 'react-router-dom';
//MUI
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
//Components
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { VisitTypeOptions } from './VisitType';
import { SurveysGrid } from './SurveysGrid';
import { ContinuosImpGrid } from './VisitCountinuousImp';
import { KeyItemsVisitGrid } from '../KeyItems/KeyItemsGrid';
import ActionItemsGrid from '../ActionItem/ActionItemsGrid';
import { FormulaSelection } from '../Formula/FormulaSelectionView';
import { PlantLink } from '../MetaData/Links';
import { FormulasEditView } from '../Formula/FormulasEditView';
import { LineEditView } from '../PlantEdit/LineEditView';
import MeasurementsView from './MeasurementsView';
import MeasurementsList from './MeasurementsList';
import InventoryGrid from './InventoryGrid';
import { DateView } from '../MetaData/MiscViews';
import { VisitHeader } from './VisitHeader';
import { VisitCloseButton } from './VisitCommon';
//Actions
import { UpdateAction } from '../../actions/DataActions';

//Misc

import StarchKitchenItemsView from './StarchKitchenItemsView';
import StarchKitchenItemsList from './StarchKitchenItemsList';
import { GetAllVisitData,ForceGetAllVisitData } from '../../actions/VisitActions';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));
const VisitOverviewView = props => {
    const [isStarchKitchenOpen, setIsStarchKitchenOpen] = useState(false);
    const [isStarchChOpen, setIsStarchChOpen] = useState(false);
    const starchKitchenRef = useRef(null);
    const starchChRef = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate=useNavigate();
    const location = useLocation();
    const visit = useSelector(state => state.Visits.find((v) => v.id == params.VisitID), shallowEqual);
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (location.search === '?starchKitchen') {
            setIsStarchKitchenOpen(true);
            if (starchKitchenRef.current) {
                starchKitchenRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        if (location.search.split('-')[0] === '?StartchChecks') {
            setIsStarchChOpen(true);
            if (starchChRef.current) {
                starchChRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location.search]);

    //   useEffect(() => {
    //     if (isStarchKitchenOpen && starchKitchenRef.current) {
    //         // Smooth scroll to the Starch Kitchen accordion when it opens
    //     }
    // }, [isStarchKitchenOpen]);

    const closeVisit = () => navigate(-1);

    const handleStarchKitchenClick = () => {
        setIsStarchKitchenOpen(!isStarchKitchenOpen); // Toggle the state
    };
    const handleStarchChClick = () => {
        setIsStarchChOpen(!isStarchChOpen); // Toggle the state
    };

    const ForceReSync = () => ForceGetAllVisitData(dispatch, visit);
    useEffect(() => dispatch(GetAllVisitData(visit)), [JSON.stringify(visit)]);
    console.log("VisitOverview View Render", visit);
    if (!visit) {
        return (<div>
            Loading Visit From Database... 
            <VisitCloseButton closeVisit={closeVisit} />
            </div>
            );
    }
    return (
        <div>     
                <VisitHeader visit={visit} ReSync={ForceReSync} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_keyitems_accord"
                        id="visit_keyitems_accord"
                    >
                        <Typography className={classes.heading}>Key Items</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <KeyItemsVisitGrid visitID={params.VisitID} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_actions_accord"
                        id="visit_actions_accord"
                    >
                        <Typography className={classes.heading}>Actions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ActionItemsGrid plantID={visit.plantID} visit={visit} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_surveys_accord"
                        id="visit_surveys_accord"
                    >
                        <Typography className={classes.heading}>Surveys</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SurveysGrid visitID={params.VisitID} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_formula_accord"
                        id="visit_formula_accord"
                    >
                        <Typography className={classes.heading}>Formula</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormulasEditView plantID={visit.plantID} visitID={visit.id} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_corrgator_accord"
                        id="visit_corrgator_accord"
                    >
                        <Typography className={classes.heading}>Corrugator Layout</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LineEditView plantID={visit.plantID} visitID={visit.id} />
                    </AccordionDetails>
                </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="visit_keyind_accord"
                    id="visit_keyind_accord"
                >
                        <Typography className={classes.heading}>Key Indicators</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <DataForm actionType={'UpdateVisit'} data={visit}>
                        <VisitKeyInd plantID={ visit.plantID } />
                        </DataForm>
                </AccordionDetails>
            </Accordion>
                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_measurements_accord"
                        id="visit_measurements_accord"
                    >
                        <Typography className={classes.heading}>Starch Checks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MeasurementsView  plantID={visit.plantID} visitID={visit.id} />
                    </AccordionDetails>
                </Accordion> */}
                <Accordion expanded={isStarchChOpen} ref={starchChRef}>
                    <AccordionSummary onClick={handleStarchChClick}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_starchkitchen_accord"
                        id="visit_starchkitchen_accord"
                    >
                    <Typography className={classes.heading}>Starch Checks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <MeasurementsList  plantID={visit.plantID} visitID={visit.id} />
                    </AccordionDetails>
                </Accordion>

                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_starchkitchen_accord"
                        id="visit_starchkitchen_accord"
                    >
                        <Typography className={classes.heading}>Starch Kitchen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StarchKitchenItemsView visit={visit} />
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion expanded={isStarchKitchenOpen} ref={starchKitchenRef}> */}
                <Accordion expanded={isStarchKitchenOpen} ref={starchKitchenRef}>
                    <AccordionSummary onClick={handleStarchKitchenClick}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_starchkitchen_accord"
                        id="visit_starchkitchen_accord"
                    >
                    <Typography className={classes.heading}>Starch Kitchen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <StarchKitchenItemsView visit={visit} /> */}
                        <StarchKitchenItemsList visit={visit} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_inventory_accord"
                        id="visit_inventory_accord"
                    >
                        <Typography className={classes.heading}>Inventory</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InventoryGrid plantID={visit.plantID} visitID={visit.id} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visit_continuous_improvement"
                        id="visit_continuous_improvement"
                    >
                        <Typography className={classes.heading}>Continuous Improvement</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ContinuosImpGrid plantID={visit.plantID} visitID={visit.id}  />
                    </AccordionDetails>
                </Accordion>
                
        </div>
    );
};
export default VisitOverviewView;

export const VisitKeyInd = props => {
    //DefaultFormulaID -Guid
    //DefaultCostPerGal
    return <Paper>
        <Grid container spacing={3}>
            <Grid item>
                <DataSelect name={'month'} label={'Month'} options={ MonthOptions } />
            </Grid>
            <Grid item>
                <DataNumber name={'waste'} label={'Waste'}  />
            </Grid>
            <Grid item>
                <DataNumber name={'basisWeight'} label={'BasisWeight'} />
            </Grid>
            <Grid item>
                <DataNumber name={'linearFeetPerHour'} label={'LinearFeetPerHour'} />
            </Grid>
            <Grid item>
                <DataNumber name={'consumption'} label={'Consumption'} />
            </Grid>
            <Grid item>
                <VisitDefaultFormSel /><DataNumber name={'defaultCostPerGal'} label={'Cost Per Gal'} disabled />
            </Grid>
        </Grid>
    </Paper>;
}
export const MonthOptions = [
    <option key={'month_1'} value={'Jan'}>Jan</option>,
    <option key={'month_2'} value={'Feb'}>Feb</option>,
    <option key={'month_3'} value={'Mar'}>Mar</option>,
    <option key={'month_4'} value={'Apr'}>Apr</option>,
    <option key={'month_5'} value={'May'}>May</option>,
    <option key={'month_6'} value={'Jun'}>Jun</option>,
    <option key={'month_7'} value={'Jul'}>Jul</option>,
    <option key={'month_8'} value={'Aug'}>Aug</option>,
    <option key={'month_9'} value={'Sep'}>Sep</option>,
    <option key={'month_10'} value={'Oct'}>Oct</option>,
    <option key={'month_11'} value={'Nov'}>Nov</option>,
    <option key={'month_12'} value={'Dec'}>Dec</option>,
];
export const VisitDefaultFormSel = props => {
    const dispatch = useDispatch();
    const contextDF = useContext(DataFormContext);
    const handleChange = (e) => {

        dispatch(UpdateAction(contextDF.actionType, 'defaultFormulaID', contextDF.data, e));
        //Thunk Hack
        dispatch((dispatch, getState) => {
            const state = getState();
            const formula = state.Formulas.find(f => f.id === e);
            if (formula) {
                dispatch(UpdateAction(contextDF.actionType, 'defaultCostPerGal', contextDF.data, formula.costGal));
            }
        });
        
    }
    if (!contextDF || !contextDF.data) {
        return <>Loading ...</>;
    }
    return (
        <>
            <FormulaSelection plantID={contextDF.data.plantID} handleChange={handleChange} selected={contextDF.data.defaultFormulaID} />
        </>
    );
};
