import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { DataNumber } from '../FormManager';
import { Card, CardContent, CardHeader, InputAdornment, Typography } from '@mui/material';
const constants = Constants;
const DownTime = props => {
    const { selectedBaseline, selectedDays, DetailObj, ErrObj } = props
    // console.log('---------------------------------------------------', props);
    const [TotalLineal, setTotalLineal] = React.useState(0);
    const [TotalSQFT, setTotalSQFT] = React.useState(0);
    const [MstPerDay, setMstPerDay] = React.useState(0);
    const [LbsPerDay, setLbsPerDay] = React.useState(0);
    const [hoursOfDownTimeErr, sethoursOfDownTimeErr] = React.useState(ErrObj.hoursOfDownTimeErr);
    const [reqObj, setreqObj] = React.useState(
        {
            hoursOfDownTime: 0,
            totalDownTimeSaved: 0,
        }
    );
    useEffect(() => {
        sethoursOfDownTimeErr(DetailObj.hoursOfDownTime > 0 ? '': 'Required')
    }, [ErrObj]);
    useEffect(() => {
        let data = reqObj
        data.hoursOfDownTime = DetailObj.hoursOfDownTime,
            data.totalDownTimeSaved = DetailObj.totalDownTimeSaved,

            setreqObj({ ...data })
            // console.log('check dependan');
    }, [DetailObj]);
    useEffect(() => {
        if (DetailObj.hoursOfDownTime > 0) {
            DonetimeHandle(DetailObj.hoursOfDownTime)
        }
    }, []);

    const DonetimeHandle = (value) => {
        // console.log('cjecl 111111111111111111');
        const data = reqObj
            let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
            let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
            

            let TotalLineal = value * selectedBaseline.avgSpeed
            let TotalSQFT = TotalLineal * (selectedBaseline.avgWidth/12) 
            let MstPerDay = TotalSQFT / 1000
            let LbsPerDay = MstPerDay * selectedBaseline.baseGrade

            setTotalLineal (TotalLineal)
            setTotalSQFT (TotalSQFT)
            setMstPerDay (MstPerDay)
            setLbsPerDay (LbsPerDay)

            data.hoursOfDownTime = value
            data.totalDownTimeSaved = MstPerDay * costMSFWperPP
            setreqObj({ ...data })
        props.DonetimeHandle(data)

    }

   

    const validate = (values) => {

        // console.log('cjeclllllll', values);
        const errors = {};
        if (values.hoursOfDownTime === 0)
            errors.hoursOfDownTime = "Must have value";
        if (values.hoursOfDownTime < 0)
            errors.hoursOfDownTime = "invalid input";
        sethoursOfDownTimeErr('')
        // console.log('hendelconsLbPerMsf+++++++++++++++++++++++++++++', errors);
        if (!errors.hoursOfDownTime) {
            DonetimeHandle(values.hoursOfDownTime)
        } else {
            setTotalLineal (0)
            setTotalSQFT (0)
            setMstPerDay (0)
            setLbsPerDay (0)
        }
        props.updateRequest(values.hoursOfDownTime, 'hoursOfDownTime')
        return errors;
    }

    

    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {
            return "Invalid Number";                                    
        }
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            let formattedNumber = Number(number).toFixed(2);
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
        }
    }
    const handleFocus = (e) => {
        e.target.select(); // Selects the input value
    };
    if (selectedBaseline !== null) {
        return <>
            <Grid container xl={12} style={{ padding: '16px', marginTop: '20px' }}>
                <Grid item xl={12}>
                <Formik initialValues={reqObj} validate={validate} validateOnBlur={true}>
                        <Form>
                            <Card variant="outlined" >
                                <CardHeader title="Downtime" />
                                <CardContent>
                                    <Grid style={{ paddingLeft: '5px' }}>
                                        <Grid spacing={1} container item xs={12} sx={{ borderTop: 1, borderLeft: 1 }}>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <div>Hours DT saved</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={2} align="center">
                                                <div>
                                                    Total Lineal
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={2} align="center">
                                                <div>
                                                    Total sq.FT.
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={2} align="center">
                                                <div>
                                                    MSF/day
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={2} align="center">
                                                <div>
                                                    Lbs/day
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                
                                                <Field id='downTImesec' onWheel={(e) => e.target.blur()} style={{}} label="Hours DT saved" component={TextField} name="hoursOfDownTime" type="number"
                                                    onFocus={handleFocus} inputProps={{min: 0, step: "any"}} onKeyDown={(e) => {
                                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
 {
                                                    hoursOfDownTimeErr !== "" ? <Typography align='' style={{ fontSize: '0.75rem' }} color="error">{hoursOfDownTimeErr}</Typography> : <></>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={2} style={{alignItems: 'center', display: 'flex', justifyContent: 'end'}} align="right">
                                                {formatnum(TotalLineal)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} style={{alignItems: 'center', display: 'flex', justifyContent: 'end'}} item xs={2} align="right">
                                                {formatnum(TotalSQFT)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} style={{alignItems: 'center', display: 'flex', justifyContent: 'end'}} item xs={2} align="right">
                                                {formatnum(MstPerDay)}
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} style={{alignItems: 'center', display: 'flex', justifyContent: 'end'}} item xs={2} align="right">
                                                {formatnum(LbsPerDay)}
                                            </Grid>
                                            {/* <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="center">
                                                <div>
                                                    $/hour
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="center">
                                                <div>
                                                    Total Cost Saved
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <DataNumber handelChange={handelhoursOfTr} noBlurAction={true} name={'hoursOfTraining'} label={'Hours of training'} />
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <DataNumber handelChange={handelAmountPrHr} noBlurAction={true} name={'amountPerHr'} label={'  $/hour'} />
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>$</p>
                                                            <p>-</p>
                                                        </div>
                                            </Grid> */}


                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </>
    }

};
export default DownTime;