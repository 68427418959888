import Constants from '../../constants';
//Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from "mui-datatables";
//Compontents
import AddCustomToolbar from "../AddCustomToolbar";
import CustomSelectToolbar from '../CustomSelectToolbar';
import { DateView } from '../MetaData/MiscViews';
import KeyItemsPrint from './KeyItemsPrint';
import { GridDateFilter, DateFilterLogic, UpdateFilterList } from '../MetaData/GridDateFilter';
import { usePlantData, useReportNameFromPlantID } from '../../misc/UseFuncs';
import KeyItemEditDialog from './KeyItemEditDialog';
import { GridImageIcon } from './KeyItemCommon';
import { UpdateKeyItem } from '../../actions/KeyItemActions';
const PlantKeyItemsGrid = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let plantID = props.plantID;
    const plant=usePlantData(plantID);
    const [modalOpen, setModal] = useState(false);
    const [printOpen, setPrint] = useState(false);
    const [kiSort, setKISort] = useState(null);
    const [filter, setFilter] = useState({ entityTypes: [], referecnes: [], visitStartDate: [],visitTypes:[],notes:null,rows:15,page:0 });
    const [page,setPage]=useState(0);
    const [rows,setRows]=useState(15);
    const visits = useSelector(s => s.Visits.filter(v => v.plantID == plantID  && !v.deleted), shallowEqual);
    const visitIDs = visits.map(v => v.id);
    const keyItemsFilter = ki => {
        if(ki.deleted)
            return false;
        if (!visitIDs.includes(ki.visitID))
            return false;
        return true;
    };
    const keyItems = useSelector(state => state.KeyItems.filter(keyItemsFilter), shallowEqual);
    const keyHistItems = keyItems
    .map(ki => {
        const v = visits.find(v => v.id == ki.visitID);
        return { ...ki, ...v, id: ki.id, kiType: ki.type };
    })
    .sort((a, b) => {
      
        if (a.kiType === 'Safety' && b.kiType !== 'Safety') {
            return -1; 
        }
        if (b.kiType === 'Safety' && a.kiType !== 'Safety') {
            return 1; 
        }
    
        if (a.isPriority === 'Yes' && b.isPriority !== 'Yes') {
            return -1; 
        }
        if (b.isPriority === 'Yes' && a.isPriority !== 'Yes') {
            return 1; 
        }
    
        if (a.entityType === b.entityType) {
            return 0; 
        }
        if (a.entityType < b.entityType) {
            return -1; 
        }
        if (a.entityType > b.entityType) {
            return 1; 
        }

        
        const da = new Date(a.startDate);
        const db = new Date(b.startDate);
        return db - da; 
    })
    const customSort = (colName, direction) => {
        setKISort({ column: colName, direction: direction });
    }
    const [selectedRows, setSelectedRows] = useState([]);
    let keyItem = null;
    if (selectedRows.length > 0) {
        keyItem = keyHistItems[selectedRows[0]];
    }
    const columns = [
        { name: "id", label: "ID", options: { display: false,filter:false } },
        {
            name: "startDate", label: "Visit Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <DateView value={value} />;
                },
                filterType: 'custom',
                customFilterListOptions: {
                    update: UpdateFilterList
                },
                filterOptions: {
                    logic: DateFilterLogic,
                    display: (filterList, onChange, index, column) => <GridDateFilter filterList={filterList} onChange={onChange} index={index} column={column} />,
                    fullWidth: true

                }
            }
        },
        { name: 'type', label: 'Visit Type', options: { filterType: 'multiselect' } },
        { name: "entityType", label: "Type", options: { filterType: 'multiselect' } },
        { name: "reference", label: "Section", options: { filterType: 'multiselect' } },
        { name: "kiType", label: "Category", options: { filterType: 'multiselect' } },
        { name: "notes", label: "Notes", options: { filterType:'textField'}, },
        { name: "isPriority", label: "Priority", options: { filter:true } },
        { name: "imageID", label:"Image",options:{
            customBodyRender: (value, tableMeta, updateValue) => <GridImageIcon value={value}  keyItem={keyHistItems[tableMeta.rowIndex]} onChange={editSubmit}   />,
            filter: false
        }}
    ];
    const ClickOpen = () => {
        if (keyItem) {
            navigate('/'+keyItem.url);
        } else {
            alert("Select Key Item From List");
        }
    };
    const rowSelect = (rowsSelectedData, allRows, rowsSelected) => {
        setSelectedRows(rowsSelected);
    };
    const onFilterChange = (col, filterList, type, ind, displayData) => {
        setFilter({ visitStartDate: filterList[1], visitTypes: filterList[2], entityTypes: filterList[3], referecnes: filterList[4], notes: filterList[5].length > 0 ? filterList[5][0]:null });
    };
    const ClickEdit = () => { setModal(true); };
    const editClose = () => { setModal(false); };
    const editSubmit = (formVal) => {     
        dispatch(UpdateKeyItem(formVal, keyItem));
        setModal(false);        
    };
    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        page:page,
        rowsPerPageOptions:[15,30,100,250,500],
        rowsPerPage:rows,
        onChangeRowsPerPage:(newRows)=>{
            let newPage=page;
            if(newRows>rows){
                setPage(0);
                newPage=0;
            }
            setRows(newRows);
            setFilter({ ...filter, rows:newRows,page:newPage});
            console.log("KeyItem New Rows:"+newRows);
        },
        onChangePage:(newPage)=>{
            setPage(newPage);
            setFilter({ ...filter, page:newPage});
            console.log("KeyItem New Page:"+newPage);
        },
        onColumnSortChange: customSort, onFilterChange: onFilterChange,
        customToolbar: () => {
            return (
                <AddCustomToolbar clickPrint={() => setPrint(true)} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                clickLink={ClickOpen} clickEdit={ClickEdit} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: selectedRows, filter: true, viewColumns: true
    };
    const onChangePriority = (value) => {
        keyItem.isPriority = value ? 'Yes' : '-'
    }
    return (
        <>
        <MUIDataTable
            title={"Key Items"}
            data={keyHistItems}
            columns={columns}
            options={options}
            />
            <Dialog open={printOpen} onClose={() => setPrint(false)} fullScreen>
                <KeyItemsPrint keyItems={keyHistItems} sort={kiSort} filter={filter} 
                printHistory onClose={() => setPrint(false)} plant={plant} visit={{}} />
            </Dialog>
            <KeyItemEditDialog onChangePriority={onChangePriority} modalOpen={modalOpen} modalClose={editClose}
                    keyItem={keyItem} onSubmit={editSubmit} 
                />
        </>
    );
}
export default PlantKeyItemsGrid;